<template>
    <div class="question-draft-preview">
        <Teleport to="#app">
            <ModalContainer
                key="questionDraftPreview"
                @close="closePreview"
            >
                <template #modal>
                    <div id="questionDraftPreview" class="question-draft-preview__modal-div">
                        <div class="question-draft-preview__modal-container">
                            <div class="question-draft-preview__modal-header" v-if="question">
                                <div class="question-draft-preview__modal-title">
                                    <span>Question Preview</span>
                                    <span v-if="question.questionScenario">
                                        This is how the question will look to students.
                                    </span>
                                    <span v-else>
                                        This is how the question will look to students in our web tool.
                                        Order of answers and distractors will be randomized.
                                    </span>
                                </div>
                                <div class="question-draft-preview__buttons">
                                    <PocketButton
                                        v-if="scenarioQuestionDrafts.length || scenarioQuestions.length"
                                        class="question-draft-preview__edit-question-btn"
                                        :disabled="questionInViewSerial === qDraftFormData.serial"
                                        type="primary"
                                        @click="openQuestionFromScenario"
                                    >
                                        Edit Question 
                                    </PocketButton>
                                    <PocketButton
                                        class="question-draft-preview__reset-question-btn"
                                        type="primary"
                                        @click="reviewMode = !reviewMode"
                                    >
                                        {{ reviewMode ? 'Reset Question' : 'Show Answer' }}
                                    </PocketButton>
                                    <PocketButton
                                        class="question-draft-preview__copy-to-clipboard"
                                        type="primary"
                                        @click="copyToClipboard()"
                                    >
                                        <Icon
                                            type="correct"
                                            v-if="showQuestionCopiedText"
                                            class="question-draft-preview__copy-to-clipboard-icon"
                                        />
                                        {{ showQuestionCopiedText ? 'Copied!' : 'Copy to Clipboard' }}
                                    </PocketButton>
                                    <Icon
                                        class="question-draft-preview__close-icon"
                                        type="close"
                                        title="Close"
                                        @click="closePreview"
                                    />
                                </div>
                            </div>
                            <Modal class="question-draft-preview__modal">
                                <div
                                    ref="question-draft-preview__text-mode"
                                    class="question-draft-preview__text-mode"
                                    id="copyQuestionDiv"
                                    v-if="question"
                                >
                                    <strong>QUESTION</strong>
                                    <span v-html="question.prompt" />
                                    <br>
                                    <template v-if="question.passage">
                                        <strong>PASSAGE</strong>
                                        <span v-html="question.passage" />
                                        <br>
                                    </template>
                                    <template
                                        v-if="question.type === 'Matrix Checkbox'
                                            || question.type === 'Matrix Radio Button'"
                                    >
                                        <strong>COLUMN LABEL FOR ROWS</strong>
                                        <span v-html="question.matrixLabels?.columnLabelForRows"></span>
                                        <span
                                            v-for="(column, index) in question.matrixLabels?.columns"
                                            :key="`column-${index}`"
                                        >
                                            <strong>COLUMN {{ index + 1 }}</strong>
                                            <span v-html="column"></span>
                                        </span>
                                        <span
                                            v-for="(row, index) in question.matrixLabels?.rows"
                                            :key="`row-${index}`"
                                        >
                                            <strong>ROW {{ index + 1 }}</strong>
                                            <span v-html="row"></span>
                                            <span>
                                                {{ ` [Selections: ${rowSelectionsByIndexLib
                                                    ? rowSelectionsByIndexLib[index].join(', ') : 'none'} ]` }}
                                            </span>
                                        </span>
                                        <br>
                                    </template>
                                    <template v-else>
                                        <span
                                            v-for="(answer, index) in question.choices.filter(c => c.isCorrect)"
                                            :key="`answer-${index}`"
                                        >
                                            <strong>ANSWER {{ index + 1 }}</strong>
                                            <span v-html="answer.text" />
                                            <br>
                                        </span>
                                        <span
                                            v-for="(distractor, index) in question.choices.filter(c => !c.isCorrect)"
                                            :key="`distractor-${index}`"
                                        >
                                            <strong>DISTRACTOR {{ index + 1 }}</strong>
                                            <span v-html="distractor.text" />
                                            <br>
                                        </span>
                                    </template>
                                    <strong>EXPLANATION</strong>
                                    <span v-html="question.explanation" />
                                    <br>
                                    <template v-if="question.references && question.references.length">
                                        <strong>REFERENCES</strong>
                                        <span
                                            v-for="(reference) in question.references"
                                            :key="reference"
                                        >
                                            <span v-html="reference" />
                                        </span>
                                    </template>
                                </div>
                                <div>
                                    <Question
                                        v-if="question"
                                        class="question-draft-preview__question"
                                        :key="`${questionInViewSerial}-${reviewMode}`"
                                        :question="question"
                                        :show-check-answer="true"
                                        :container-el="questionDraftPreviewEl"
                                        :review-mode="reviewMode"
                                        :show-next-question="false"
                                        @checkAnswer="reviewMode = !reviewMode"
                                    />
                                </div>
                            </Modal>
                            <div 
                                class="question-draft-preview__footer"
                                v-if="currentQuestionScenario || currentQuestionScenarioDraft"
                            >
                                <div class="question-draft-preview__controls">
                                    <Icon
                                        class="question-draft-preview__previous-arrow"
                                        :class="{'question-draft-preview__previous-arrow--disabled'
                                            : !previousQuestionSerial}"
                                        type="paginationArrow"
                                        @click="previousQuestionInScenario"
                                    />
                                    <Icon
                                        class="question-draft-preview__next-arrow"
                                        :class="{'question-draft-preview__next-arrow--disabled': !nextQuestionSerial}"
                                        type="paginationArrow"
                                        @click="nextQuestionInScenario"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </ModalContainer>
        </Teleport>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from 'vue-facing-decorator'
import type QuestionDraftForm from './QuestionDraftForm'
import UIKit from '@pocketprep/ui-kit'
import questionsModule from '@/store/questions/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import type { CMS } from '@pocketprep/types'
import type { TEnhancedQuestion } from '@/store/questions/types'

interface IReference {
    text: string
    id: number
}

@Component({
    components: {
        Question: UIKit.Question,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
    },
})
export default class QuestionDraftPreview extends Vue {
    @Prop() readonly matrixChoiceLayout?: string[][]

    reviewMode = false
    questionDraftPreviewEl: HTMLElement | null = null
    scenarioQuestionDrafts: CMS.Class.QuestionDraftJSON[] = []
    scenarioQuestions: TEnhancedQuestion[] = []
    questionInViewSerial = ''
    showQuestionCopiedText = false

    get qDraftFormData () {
        return this.$parent?.$data as QuestionDraftForm
    }

    get questionDraftChoices () {
        let currentQuestionDraftChoices = [ ...this.qDraftFormData.answers, ...this.qDraftFormData.distractors ]
        const isViewingAnotherQuestion = this.scenarioQuestionDrafts
        && this.questionInViewSerial !== this.qDraftFormData.serial

        if (isViewingAnotherQuestion) {
            const anotherQuestion = this.scenarioQuestionDrafts.find(qd => qd.serial === this.questionInViewSerial)
            if (anotherQuestion?.answers && anotherQuestion.distractors) {
                currentQuestionDraftChoices = [ ...anotherQuestion.answers, ...anotherQuestion.distractors ]
            }
        }
        return currentQuestionDraftChoices.map((choice, idex) => {
            const isCorrect = this.qDraftFormData.answers.includes(choice)
            return {
                text: choice,
                isCorrect: this.qDraftFormData.answers.includes(choice),
                id: `${isCorrect ? 'a' : 'd' }${idex + 1}`,
            }
        })
    }

    get currentQuestionScenario () {
        // Check for live question scenario (when viewing live question)
        return this.qDraftFormData.examQuestionScenarios.find(scenario =>
            scenario.objectId === this.qDraftFormData.questionScenarioId) || null
    }

    get currentQuestionScenarioDraft () {
        // Otherwise check question scenario drafts
        const questionScenarioDraft = this.qDraftFormData.examQuestionScenarioDrafts.find(scenarioDraft =>
            scenarioDraft.objectId === this.qDraftFormData.questionScenarioDraftId
        ) || null

        if (questionScenarioDraft) {
            const questionScenarioDraftPreview = {
                ...questionScenarioDraft,
                // renaming questionDrafts to questions so Question component can consume it
                questions: questionScenarioDraft?.questionDrafts,
            }
            return questionScenarioDraftPreview
        } else {
            return null
        }
    }

    get currentScenarioDraftQuestions () {
        const currentQuestionScenarioDraftOrLiveScenario = this.currentQuestionScenarioDraft
        || this.currentQuestionScenario
        return currentQuestionScenarioDraftOrLiveScenario?.questions?.map(q => q.serial) || []
    }

    get indexOfCurrentQuestionInScenarioArray () {
        return this.currentScenarioDraftQuestions?.indexOf(this.questionInViewSerial)
    }

    get nextQuestionSerial () {
        return this.currentScenarioDraftQuestions[this.indexOfCurrentQuestionInScenarioArray + 1]
    }

    get previousQuestionSerial () {
        return this.currentScenarioDraftQuestions[
            this.indexOfCurrentQuestionInScenarioArray - 1]
    }

    get explanationImage () {
        return this.qDraftFormData.explanationImageUrl ? 
            {
                url: this.qDraftFormData.explanationImageUrl || '',
                altText: this.qDraftFormData.explanationImageAltText || '',
                longAltText: this.qDraftFormData.explanationImageLongAltText || '',
            } : ''
    }

    get passageImage () {
        return this.qDraftFormData.passageImageUrl ?
            {
                url: this.qDraftFormData.passageImageUrl,
                altText: this.qDraftFormData.passageImageAltText || '',
                longAltText: this.qDraftFormData.passageImageLongAltText || '',
            } : ''
    }

    get rowSelectionsByIndexLib () {
        return this.question?.matrixChoiceLayout?.reduce((acc: Record<number, string[]>, curr, index) => {
            const selections = curr.filter(choiceCell => choiceCell.startsWith('a'))
                .map(choiceCell => {
                    const [ , number ] = choiceCell.split('_')
                    return `Column ${number}` 
                })
            acc[index] = selections
            return acc
        }, {})
    }

    get question () {
        if (this.scenarioQuestionDrafts && this.questionInViewSerial && this.qDraftFormData.serial
        && this.questionInViewSerial !== this.qDraftFormData.serial) {
            const questionDraft = this.scenarioQuestionDrafts.find(qd => qd.serial === this.questionInViewSerial)
            const liveQuestion = this.scenarioQuestions.find(q => q.serial === this.questionInViewSerial)
            if (questionDraft) {
                return {
                    prompt: questionDraft.prompt,
                    choices: this.questionDraftChoices,
                    passage: questionDraft.passage,
                    passageImage: questionDraft.images?.passage,
                    explanation:  questionDraft.explanation,
                    explanationImage: questionDraft?.images?.explanation,
                    references: questionDraft.references,
                    serial: questionDraft.serial || '',
                    type: questionDraft.type,
                    objectId: questionDraft.objectId,
                    isArchived: questionDraft.isArchived,
                    isFree: questionDraft.isSpecial,
                    matrixLabels: questionDraft.matrixLabels,
                    matrixChoiceLayout: questionDraft.matrixChoiceLayout,
                    questionScenario: this.currentQuestionScenarioDraft,
                }
            } else if (liveQuestion) {
                return {
                    prompt: liveQuestion.prompt,
                    choices: liveQuestion.choices,
                    passage: liveQuestion.passage,
                    passageImage: liveQuestion.passageImage,
                    explanation:  liveQuestion.explanation,
                    explanationImage: liveQuestion.explanationImage,
                    references: liveQuestion.references,
                    serial: liveQuestion.serial || '',
                    type: liveQuestion.type,
                    objectId: liveQuestion.objectId,
                    isArchived: liveQuestion.isArchived,
                    isFree: liveQuestion.isFree,
                    matrixLabels: liveQuestion.matrixLabels,
                    matrixChoiceLayout: liveQuestion.matrixChoiceLayout,
                    questionScenario: this.currentQuestionScenario,
                }
            } else {
                return null
            }
        } else {
            return {
                prompt: this.qDraftFormData.prompt,
                choices: this.questionDraftChoices,
                passage: this.qDraftFormData.passage,
                passageImage: this.passageImage,
                explanation:  this.qDraftFormData.explanation,
                explanationImage: this.explanationImage,
                references: this.formatReferencesArray(this.qDraftFormData.references),
                serial: this.qDraftFormData.serial || '',
                type: this.qDraftFormData.questionDraftType,
                isArchived: this.qDraftFormData.isArchived,
                isFree: this.qDraftFormData.isSpecial,
                matrixLabels: this.qDraftFormData.matrixLabels,
                matrixChoiceLayout: this.matrixChoiceLayout,
                questionScenario: this.currentQuestionScenarioDraft || this.currentQuestionScenario,
            }
        }
    }

    async mounted () {
        this.questionDraftPreviewEl = document.getElementById('questionDraftPreview') || null

        if (this.currentQuestionScenarioDraft?.objectId) {
            this.scenarioQuestionDrafts = await questionDraftsModule.actions
                .fetchQuestionDraftsByQuestionScenarioDraftId(this.currentQuestionScenarioDraft.objectId)
        }

        if (this.currentQuestionScenario) {
            const scenarioQuestionPromises = this.currentQuestionScenario.questions.map(question => (
                questionsModule.actions.fetchQuestions({
                    equalTo: {
                        serial: question.serial,
                    },
                })
            ))
            const scenarioQuestions = await Promise.all([ ...scenarioQuestionPromises ])

            this.scenarioQuestions = scenarioQuestions.map(sq => sq.results[0])
        }

        this.questionInViewSerial = this.qDraftFormData.serial || ''
    }

    nextQuestionInScenario () {
        const nextQuestionSerial = this.nextQuestionSerial

        if (!nextQuestionSerial) {
            return
        } else {
            this.questionInViewSerial = nextQuestionSerial
        }
    }

    previousQuestionInScenario () {
        const previousQuestionSerial = this.previousQuestionSerial

        if (!previousQuestionSerial) {
            return
        } else {
            this.questionInViewSerial = previousQuestionSerial
        }
    }

    openQuestionFromScenario () {
        const questionId = this.scenarioQuestions.find(sq => sq.serial === this.questionInViewSerial)?.objectId
        const questionDraftId = this.scenarioQuestionDrafts
            .find(sqd => sqd.serial === this.questionInViewSerial)?.objectId
        if (questionId) {
            this.$router.replace({
                name: 'question-draft-create',
                query: { 'question': this.question && this.question.objectId },
            })
        } else {
            this.$router.push({
                name: 'question-draft-edit',
                params: {
                    questionDraftId: String(questionDraftId),
                },
            }).then(() => {
                this.closePreview()
            })
        }
    }

    formatReferencesArray (references: IReference[]) {
        return references.map(ref => ref.text)
    }

    openTextPreview () {
        const popup = window.open('', `q_${new Date().getTime()}`, 'width=600, height=500'),
            content = (this.$refs['question-draft-preview__text-mode'] as HTMLDivElement).innerHTML
        popup
            && popup.document.write(`<html>${ content }</html>`)
    }

    processNode (node: Node) {
        let text = ''

        if (node.nodeType === Node.TEXT_NODE) {
            text += node.nodeValue
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const nodeAsElement = node as HTMLElement
            const tagName = nodeAsElement.tagName.toLowerCase()
            const parentNode = nodeAsElement.parentNode as HTMLElement

            if (tagName === 'strong') {
                if (parentNode && parentNode.previousElementSibling
                && !nodeAsElement.innerText.toLowerCase().includes('answer')
                && !nodeAsElement.innerText.toLowerCase().includes('distractor')) {
                    text += '\n'
                }
                text += '\n' + this.processNodeChildren(node) + '\n'
            } else if (tagName === 'br') {
                text += '\n'
            } else {
                text += this.processNodeChildren(node)
            }
        }

        return text
    }

    processNodeChildren (parent: Node) {
        let text = ''
        parent.childNodes.forEach((child) => {
            text += this.processNode(child)
        })
        return text
    }

    async copyToClipboard () {
        const element = document.getElementById('copyQuestionDiv') as HTMLElement

        if (!element || !(element instanceof HTMLElement)) {
            throw new Error('A valid HTML element must be provided.')
        }

        // Generate plain text from the element
        const plainText = this.processNodeChildren(element).trim()

        // Copy the text to the clipboard
        try {
            await navigator.clipboard.writeText(plainText)
            this.showQuestionCopiedText = true
            setTimeout(() => {
                this.showQuestionCopiedText = false
            }, 2000)
        } catch (error) {
            alert('Failed to copy content to clipboard. Please check permissions.')
        }
    }

    @Emit('close')
    closePreview () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.question-draft-preview {
    &__modal-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
        position: fixed;
        z-index: 200;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1312px;
        height: 777px;
        left: 0;
        border-radius: 12px;

        @media screen and (max-height: 880px) {
            margin-top: 0;
            height: calc(100% - 57px)
        }
    }

    &__modal-container {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
    }

    &__modal {
        background-color: $gray-background;
        height: 100%;
        width: 100%;
        overflow: scroll;
        position: relative;

        :deep(.uikit-modal__close) {
            font-size: 14px;
        }
    }

    &__modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        border-bottom: 1px solid $fog;
        height: 54px;
        width: 100%;
        position: absolute;
        z-index: 100000;
        border-radius: 12px 12px 0 0;
    }

    &__modal-title {
        color: $slate;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        padding-left: 20px;

        span {
            color: $brand-black;
            font-weight: 600;
            margin-right: 14px;
        }
    }

    &__text-mode {
        display: none;
    }

    &__buttons {
        position: relative;
        display: flex;
        margin-left: 50px;
    }

    &__edit-question-btn,
    &__copy-to-clipboard,
    &__reset-question-btn {
        height: 32px;
        padding: 7px 12px 6px 12px;
        margin-left: 12px;

        :deep(.uikit-btn__content) {
            font-size: 14px;
        }
    }

    &__copy-to-clipboard {
        width: 144px;
    }

    &__copy-to-clipboard-icon {
        height: 18px;
        position: absolute;
        left: 24px;
        bottom: 7px;
    }

    &__close-icon {
        color: $brand-blue;
        width: 32px;
        height: 32px;
        position: relative;
        margin-left: 20px;
        margin-right: 10px;
        cursor: pointer;
    }

    &__question {
        :deep(.uikit-question-context) {
            display: none;
        }

        :deep(.uikit-question__main) {
            overflow: hidden;
            padding-bottom: 54px;
        }

        :deep(.uikit-question__main),
        :deep(.uikit-question-passage-and-image) {
            padding-top: 124px;
        }

        :deep(.uikit-question__right-side--explanation) {
            padding-top: 81px;
        }

        :deep(.uikit-question-explanation),
        :deep(.uikit-question-explanation--tablet-landscape) {
            max-width: 566px;
            height: 100%;
            min-height: 695px;
        }

        :deep(.uikit-question__right-side),
        :deep(.uikit-question__right-side--tablet-landscape) {
            width: 46%;
        }

        :deep(.uikit-question-matrix-choices-container) {
            width: auto;
        }

        :deep(.uikit-question-matrix-choices-container__checkbox--review:not(
            .uikit-question-matrix-choices-container__checkbox--review:last-child)) {
            margin-right: 16px;
        }
    }

    &__footer {
        height: 54px;
        width: 100%;
        background-color: $brand-black;
        position: relative;
        bottom: 54px;
        z-index: 100000;
    }

    &__controls {
        width: 304px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        justify-content: space-between;
    }

    &__previous-arrow {
        transform: rotate(180deg);

        &--disabled {
            opacity: 0.35;
        }
    }

    &__next-arrow {

        &--disabled {
            opacity: 0.35;
        }
    }
}
</style>

