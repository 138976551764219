import type {
    TFetchExams,
    TFetchExam,
    TCleanUpExport,
    TCheckCompositeKeyExists,
    TExportImages,
    TDeleteExam,
    TFetchS3BucketName,
    TFetchMostRecentExamVersion,
    TUpdateBundlesForExamMajor,
    TQuestionQualityResetV2,
    TFetchSubjects,
    TUpdateRedisQuestions,
} from './types'
import { Parse, objPointer, sessionToken } from '@/store/ParseUtils'
import type { CMS, Study } from '@pocketprep/types'
import examsModule from '@/store/exams/module'

/**
 * Fetch all exams from app server and stores them in store
 *
 * @returns {Promise} resolves with IExam[]
 */
const fetchExams = async (): ReturnType<TFetchExams> => {
    const parseExams = await Parse.Cloud.run<CMS.Cloud.fetchExams>('fetchExams')
    const exams = parseExams.map(exam => exam.toJSON())

    examsModule.state.exams = exams

    return exams
}

/**
 * Fetch exam from app server by ID
 *
 * @returns {Promise} resolves with IExam[]
 */
const fetchExam = async (examId: Parameters<TFetchExam>[0]): ReturnType<TFetchExam> => {
    const parseExam = await Parse.Cloud.run<CMS.Cloud.fetchExam>('fetchExam', { examId })
    return parseExam && parseExam.toJSON()
}

/**
 * Fetch all Subjects or just from specific exam if examMetadataId is provided
 *
 * @returns {Promise} resolves with IExam[]
 */

const fetchSubjects = async (params?: Parameters<TFetchSubjects>[0]): ReturnType<TFetchSubjects> => {
    const parseSubjects = 
        await Parse.Cloud.run<TFetchSubjects>('fetchSubjects', { examMetadataId: params?.examMetadataId })
    return parseSubjects
}

/**
 * Figure out hte most recent major exam version for given composite key
 */
const fetchMostRecentExamVersion = async (
    compositeKey: Parameters<TFetchMostRecentExamVersion>[0]
): ReturnType<TFetchMostRecentExamVersion> => {
    const compositeKeyMajor = compositeKey.split('.')[0]
    const exams = await fetchExams()
    const sortedVersions = exams
        .filter(exam => exam.compositeKey.startsWith(compositeKeyMajor))
        .sort((a, b) => -a.version.localeCompare(b.version, undefined, { numeric: true }))
    const mostRecentExamVersion = sortedVersions[0].version
    return mostRecentExamVersion
}

const fetchS3BucketName = async (): ReturnType<TFetchS3BucketName> => {
    return Parse.Cloud.run<CMS.Cloud.fetchS3BucketName>('fetchS3BucketName')
}

const questionQualityResetV2 = async (
    resetPayload: Parameters<TQuestionQualityResetV2>[0]): ReturnType<TQuestionQualityResetV2> => {
    return Parse.Cloud.run<TQuestionQualityResetV2>('questionQualityReset-v2', resetPayload)
}

/**
 * Update relevant bundles with new exam ID
 */
const updateBundlesForExamMajor = async (
    updatePayload: Parameters<TUpdateBundlesForExamMajor>[0]): ReturnType<TUpdateBundlesForExamMajor> => {
    return Parse.Cloud.run<CMS.Cloud.updateBundlesForExamMajor>('updateBundlesForExamMajor', updatePayload)
}

// Move to types when stable
type ExportKeywordDefinitions = (params: {
    serials: string[]
    examMetadataId: string
    examDraftId: string
}) => void

type KeywordGenerationQueueMessage = Parse.Object<{
    questionDraft: CMS.Class.QuestionDraft | Parse.Pointer
    failed?: boolean
    isProcessing: boolean
    attemptCount: number
}>

const exportKeywordDefinitions = async (params: {
    serials: string[]
    examMetadataId: string
    examDraftId: string
}) => {
    const { serials, examMetadataId, examDraftId } = params

    return Parse.Cloud.run<ExportKeywordDefinitions>('exportKeywordDefinitions', {
        serials,
        examMetadataId,
        examDraftId,
    })
}

const fetchKeywordMessagesForDrafts = async (params: {
    questionDraftIds: string[]
}) => {
    const { questionDraftIds } = params

    const keywordMessages = await new Parse.Query<KeywordGenerationQueueMessage>('KeywordGenerationQueueMessage')
        .containedIn('questionDraft', questionDraftIds.map(q => objPointer(q)('QuestionDraft')))
        .findAll({ ...sessionToken() })

    return keywordMessages
}

/**
 * Clean up data in CMS after export
 */
const cleanUpExport = async (cleanUpPayload: Parameters<TCleanUpExport>[0]): ReturnType<TCleanUpExport> => {
    return Parse.Cloud.run<CMS.Cloud.cleanUpExport>('cleanUpExport', cleanUpPayload)
}

/**
 * Check whether exam version already exists by looking for composite key
 */
const checkCompositeKeyExists = async (
    compositeKey: Parameters<TCheckCompositeKeyExists>[0]): ReturnType<TCheckCompositeKeyExists> => {
    return Parse.Cloud.run<CMS.Cloud.checkCompositeKeyExists>(
        'checkCompositeKeyExists',
        { compositeKey }
    )
}

/**
 * Export a new exam version images to S3
 */
const exportImages = async (exportPayload: Parameters<TExportImages>[0]): ReturnType<TExportImages> => {
    return Parse.Cloud.run<CMS.Cloud.exportImages>('exportImages', exportPayload)
}

/**
 * Delete an exam and all related data from classes: exam, examMetadata, examData, and Manifest
 *
 * @param {string} examId - ID of exam to delete
 */
const deleteExam = async (examId: Parameters<TDeleteExam>[0]): ReturnType<TDeleteExam> => {
    return Parse.Cloud.run<CMS.Cloud.deleteExam>('deleteExam', { examId })
}

/**
 * Update questions in Redis
 *
 * @param {string} compositeKey - New composite key to use for getting new questions
 */
const updateRedisQuestions = 
    async (compositeKey: Parameters<TUpdateRedisQuestions>[0]): ReturnType<TUpdateRedisQuestions> =>
        Parse.Cloud.run<CMS.Cloud.updateRedisQuestions>('updateRedisQuestions', { compositeKey })

/**
 * Export ExamMetadata
 */
const exportExamMetadata = 
    async (params: Parameters<CMS.Cloud.exportExamMetadata>[0]): Promise<ReturnType<CMS.Cloud.exportExamMetadata>> =>
        Parse.Cloud.run<CMS.Cloud.exportExamMetadata>('exportExamMetadata', params)

/**
 * Export Question Data: Questions, Subjects, Scenarios, Mock Exams
 */
const exportQuestionData = 
    async (params: Parameters<CMS.Cloud.exportQuestionData>[0]): Promise<ReturnType<CMS.Cloud.exportQuestionData>> =>
        Parse.Cloud.run<CMS.Cloud.exportQuestionData>('exportQuestionData', params)

/**
 * Delete questions from previous version (if not a major update)
 */
const deleteOldParseDataV2 = 
    async (
        params: Parameters<CMS.Cloud.deleteOldParseDataV2>[0]
    ): Promise<ReturnType<CMS.Cloud.deleteOldParseDataV2>> =>
        Parse.Cloud.run<CMS.Cloud.deleteOldParseDataV2>('deleteOldParseDataV2', params)

/**
 * Fetch the exam names for exams that contain each of the provided question serials
 */
const fetchExamNamesByQuestionSerials = async (questionSerials: string[]) => {
    return Parse.Cloud.run<Study.Cloud.fetchExamNamesByQuestionSerials>('fetchExamNamesByQuestionSerials', {
        serials: questionSerials,
    })
}

export default {
    deleteOldParseDataV2,
    exportQuestionData,
    exportExamMetadata,
    fetchExams,
    fetchExam,
    fetchSubjects,
    fetchMostRecentExamVersion,
    fetchS3BucketName,
    questionQualityResetV2,
    updateBundlesForExamMajor,
    cleanUpExport,
    checkCompositeKeyExists,
    exportImages,
    deleteExam,
    updateRedisQuestions,
    fetchExamNamesByQuestionSerials,
    exportKeywordDefinitions,
    fetchKeywordMessagesForDrafts,
}
